.container {
    text-align: center;
    margin-top: 50px;
  }
  
  .title {
    color: #4CAF50; /* Green */
    margin-bottom: 20px;
  }
  
  .message {
    margin-bottom: 30px;
  }
  
  .button {
    background-color: #0047AB;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }  
  
  .search-area {
    background: url('../assets/home_page_bckg.jpg') no-repeat center center fixed; /* 'fixed' can keep the background image in place while scrolling */
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; /* This centers the content vertically */
    flex-direction: column; /* Stack children vertically */
    padding-top: 0; /* No padding at the top */
  }
  
  .motto {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    font-weight: bold;
    position: absolute;
    top: 20%; /* Adjust as needed to match your layout */
    width: 100%;
    color: white;
  }
  
  .tabs {
    position: absolute;
    top: 30%; /* Adjust this value to position your tabs as in the image */
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust gap to match your layout */
  }
  
  .tab {
    background-color: #00540d;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
    color: #ffffff;
  }
  
  .tab.active {
    background-color: #a6cd3a; /* Active tab color */
    color: white;
  }
  
  .search-form {
    position: absolute;
    top: 35%; /* Adjust this value to position your form as in the image */
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  }
  
  .input-field, .filter-btn, .search-btn, select {
    padding: 10px;
    margin: 5px; /* Adds some space around the inputs */
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  /* Style the filter button separately if needed */
  .filter-btn {
    background-color: #606060; /* Filter button background */
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* Style the search button separately if needed */
  .search-btn {
    background-color: #a6cd3a; /* Search button background */
    color: white;
    border: none;
    cursor: pointer;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .motto {
      top: 10%; /* Adjust for smaller screens */
    }
    
    .tabs {
      top: 20%; /* Adjust for smaller screens */
    }
  
    .search-form {
      top: 25%; /* Adjust for smaller screens */
      flex-direction: column; /* Stack inputs vertically on smaller screens */
    }
  }
  
  .loading-indicator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above everything else */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* CSS for the spinner */
  .spinner {
    color: #0047AB;
    animation: spin 2s linear infinite; /* Adjust '1s' to control speed */
    font-size: 2rem; /* Adjust size - This is for font icons like FontAwesome */
  }

  .searching-text {
    color: #0047AB; /* Replace with your desired color */
    font-size: 1.5rem; /* Optional: Adjust font size */
    margin-top: 0px; /* Optional: Adjust spacing */
    padding-left: 1px;
  }
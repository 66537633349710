/* LessonList.css */
.lesson-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
}

.lesson-item {
    background: rgba(94, 126, 171, 0.72);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 1em;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lesson-item h2 {
    margin: 0 0 10px 0;
    text-align: center;
}

.lesson-item-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.lesson-edit-button {
    background-color: rgb(166, 205, 58);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.lesson-delete-button {
    background-color: rgb(205, 58, 58);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.lesson-edit-button:hover {
    background-color: rgb(156, 191, 54);
}

.lesson-delete-button:hover {
    background-color: rgb(195, 44, 54);
}

.filter-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .filter-content {
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .filter-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .filter-content input,
  .filter-content select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .filter-content button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #a6cd3a;
    color: white;
    cursor: pointer;
  }
  
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* .modal {
    background: rgba(0, 71, 171, 0.9);
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: auto;
    max-width: 600px;
    z-index: 1001;
  } */

.lesson-form {
    width: 400px;
    background: rgb(0, 71, 171);
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
}

.lesson-form h1 {
    width: 100%; /* Take the full width of the form */
    margin-bottom: 1em; /* Add some space below the heading */
    text-align: center; /* No need to set text-align: center; if it's inherited from .lesson-form-container */
}

.lesson-form label,
.lesson-form input,
.lesson-form select,
.lesson-form .submit-button,
.lesson-form .add-schedule-button {
    display: block;
    margin: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}

.form-group {
    margin-bottom: 1em; /* Adds spacing between form groups */
}

.lesson-form input,
.lesson-form select,
.lesson-form textarea {
    text-align: center; /* Center aligns the text inside inputs, selects, and textareas */
}

.form-footer {
    background: #0047AB;
    padding: 10px;
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0;
    width: 100%;
}

.lesson-form .submit-button,
.lesson-form .add-schedule-button {
    display: block; /* Ensures the button is a block element */
    width: fit-content; /* Fits the width to the content of the button */
    margin: 0 auto 1em auto; /* Centers the button and adds spacing */
}

.add-schedule-button,
.submit-button {
    background-color: #a6cd3a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1em;
}

.close-button {
    background-color: #a6cd3a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1em;
}

.add-schedule-button:hover,
.submit-button:hover {
    background-color: #9cbf36;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .lesson-form-container {
        justify-content: center;
        padding-right: 0;
    }

    .lesson-form {
        width: 90%;
    }
}

.schedule-section {
    max-height: 50vh;
    overflow-y: auto;
    margin-bottom: 1em;
}

.schedule-entry {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* Blurred background style */
.modal-blur {
    filter: blur(2px);
  }
  
  /* Spinner styles */
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
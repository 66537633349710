  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    position: absolute; /* This ensures the navbar is positioned over the hero section */
    top: 0;
    left: 0;
    right: 0;
    z-index: 10; /* Higher z-index to ensure it's above the background */
    background-color: transparent; /* If you want the navbar background transparent over the hero image */
  }

  .navbar-logo {
    fill: #fff; /* This color property will change the color of the SVG logo */
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    gap: 1rem;
  }
  
  .login-button {
    background-color: #0047AB; /* Replace with the exact color code you need */
    color: white;
    padding: 15px 15px; /* Adjust padding to match the button size you want */
    border: none; /* No border */
    border-radius: 10px; /* This gives the button rounded corners */
    font-family: Arial, sans-serif; /* Replace with the exact font you want */
    font-size: 18px; /* Adjust the font size as needed */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease; /* Smooth transition for hover effect */
    outline: none; /* Removes the outline to make it look cleaner when focused */
  }
  
  .login-button:hover {
    background-color: #003484; /* A slightly darker shade for hover effect, replace with the color you need */
    transform: scale(1.05); /* Slightly enlarges the button when hovered for a subtle effect */
  }
  
  .login-button:active {
    transform: scale(0.95); /* Slightly shrinks the button when clicked for a subtle effect */
  }
  
  
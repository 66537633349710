/* CourtList.css */
.court-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    max-height: 50vh;
    overflow-x: auto;
}

.court-item {
    background: rgba(94, 126, 171, 0.72);
    padding: 20px; /* Increase padding for better spacing */
    border-radius: 8px;
    margin-bottom: 20px; /* Increase margin for more space between items */
    width: calc(100% - 40px); /* Adjust width based on padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhance shadow for depth */
    display: flex; /* Use flex to align items */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Distribute space between items */
    align-items: start; /* Align items to the start of the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.court-item h2 {
    margin: 0 0 15px 0; /* Increase bottom margin */
    font-size: 1.5em; /* Increase font size */
    font-weight: bold; /* Make it bold */
    color: #fff; /* Adjust the color if needed */
    text-align: left; /* Align text to the left */
    width: 100%; /* Ensure full width */
}

.court-item-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.court-edit-button {
    background-color: #a6cd3a;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.court-delete-button {
    background-color: #cd3a3a;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.court-edit-button:hover {
    background-color: #9cbf36;
}

.court-delete-button:hover {
    background-color: rgb(195, 44, 54);
}

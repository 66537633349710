  .lesson-result-item {
    background-color: #fff;
    margin-bottom: 20px; /* Spacing between items */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Ensures child elements do not overflow */
    width: 500px; /* Fixed width */
    margin: 20px auto; /* Center card with margin */
  }
  
  .lesson-details h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333; /* Darker text for better contrast */
  }
  
  /* New style for subheadings */
  .lesson-details .subheading {
    font-weight: bold;
    color: #333; /* Darker text for better contrast */
    margin-bottom: 5px;
    display: block; /* Make it a block element to occupy full width */
  }
  
  /* Adjust the overall padding for the content */
  .lesson-details {
    padding: 20px;
    text-align: center; /* Center the text */
    background-color: #f9f9f9; /* Light background for the content area */
    border-top: 1px solid #eee; /* Add a subtle border to the top */
  }
  
  .lesson-result-item .lesson-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .lesson-details p {
    font-size: 1em;
    line-height: 1.4; /* Reduce line height for closer lines */
    margin-bottom: 10px; /* Adjust the space between lines */
  }
  
  .lesson-details .lesson-book-button {
    background-color: #0047AB;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Specific styles for schedule list */
  .lesson-details .lesson-schedule ul {
    list-style: none; /* Remove list bullets */
    padding: 0;
    margin: 0;
  }
  
  .lesson-details .lesson-schedule li {
    padding: 5px 0; /* Adjust padding to control spacing */
  }
  
  /* Adjustments for mobile viewports */
  @media (max-width: 768px) {
    .lesson-result-item {
      margin-bottom: 15px;
    }
  
    .lesson-details {
      padding: 15px;
    }
  }
  

.lesson-schedule {
    margin: 10px 0;
  }
  
  .lesson-schedule strong {
    display: block;
    margin-bottom: 5px;
  }
  
  .lesson-schedule ul {
    list-style-type: none;
    padding: 0;
  }
  
  .lesson-schedule li {
    margin-bottom: 5px;
  }
  
  .distance-text {
    color: #a0a0a0; /* Faint grey color */
    text-align: center;
    margin-bottom: 10px;
  }
.lesson-results-container {
    position: relative;
    padding: 50px;
    padding-left: 500px; /* Increase left padding to push content to the right */
    background-image: url('../assets/court_result_bckg.png'); /* Update with the correct path */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Removed the fixed height to allow the container to grow with content */
}

.no-results-message {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
}

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .court-form {
    width: 600px;
    background: rgb(0, 71, 171);
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
  }

  .court-form h1 {
    width: 100%; /* Take the full width of the form */
    margin-bottom: 1em; /* Add some space below the heading */
    text-align: center; /* No need to set text-align: center; if it's inherited from .lesson-form-container */
  }

  .court-form label,
  .court-form input,
  .court-form select,
  .court-form .submit-button,
  .court-form .add-window-button {
    margin: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }
  
  .court-form input,
  .court-form select,
  .court-form textarea {
      text-align: center; /* Center aligns the text inside inputs, selects, and textareas */
  }

  /* Footer that contains buttons */
  .form-footer {
    background: #0047AB;
    padding: 10px;
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0;
    width: 100%;
  }
  
  .court-form .submit-button,
  .court-form .add-window-button {
      display: block; /* Ensures the button is a block element */
      width: fit-content; /* Fits the width to the content of the button */
      margin: 0 auto 1em auto; /* Centers the button and adds spacing */
  }

  /* Button styling */
  .add-window-button,
  .submit-button {
    background-color: #a6cd3a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1em;
  }

  .close-button {
    background-color: #a6cd3a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1em;
  }
  
  .add-window-button:hover,
  .submit-button:hover {
    background-color: #9cbf36;
    transform: scale(1.05);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .court-form-container {
      justify-content: center; /* Center the form on smaller screens */
      padding-right: 0; /* Remove right padding on smaller screens */
    }
  
    .court-form {
      width: 90%; /* Allow form to take more width on smaller screens */
    }
  }
  
  /* Scrollable section for pricing windows */
  .pricing-section {
    max-height: 100vh; /* Adjust the height as necessary */
    overflow-y: auto; /* Enable vertical scrolling */
    margin-bottom: 1em; /* Margin before the footer */
  }
  
  /* Individual pricing window styling */
  .pricing-window {
    background: #f8f9fa; /* Light grey background for each window */
    padding: 15px; /* Padding inside each window */
    border-radius: 8px; /* Rounded corners */
    margin-bottom: 1em; /* Space between each window */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }

  .checkbox-grid {
    display: grid;
    grid-template-columns: 1fr auto; /* Adjust this to fit content */
    gap: 1em; /* Space between label and checkbox */
    align-items: center; /* Center items vertically */
    margin-bottom: 1em; /* Space between each row */
  }
  
  .checkbox-grid label {
    justify-self: stretch; /* Align the label's end edge with the grid's end line */
  }

  .checkbox-grid input[type="checkbox"] {
    align-self: center; /* Vertically center the checkbox */
  }

/* Dropdown and Copy button styling */
.copy-pricing-dropdown,
.copy-pricing-button {
  padding: 8px 16px;
  margin-bottom: 1em; /* consistent with your form spacing */
  border-radius: 5px;
  border: 1px solid #ccc; /* subtle border */
  background-color: #f8f9fa; /* light background for the dropdown and button */
  color: #333; /* darker text for better contrast */
  cursor: pointer;
  text-align: center;
  display: inline-block; /* for side-by-side layout */
  margin-right: 10px; /* space between dropdown and button */
}

.copy-pricing-button {
  background-color: #a6cd3a;
  color: white;
  border: none;
}

.copy-pricing-button:hover {
  background-color: #9cbf36;
}

/* When applying to a specific day section */
.day-section {
  margin-bottom: 2em; /* space each day section */
}

/* Specific day-section header to distinguish from other content */
.day-section h3 {
  background-color: #0047AB;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 1em;
}

/* Schedule entry removal button within the day-section */
.remove-window-button {
  background-color: #cd3a3a;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1em; /* space it from the pricing inputs */
}

.remove-window-button:hover {
  background-color: #bb3333;
}

/* Blurred background style */
.modal-blur {
  filter: blur(2px);
}

/* Spinner styles */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* CourtResultItem.css */
.court-result-item {
    background-color: #fff;
    margin-bottom: 20px; /* Spacing between items */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Ensures child elements do not overflow */
    width: 400px; /* Fixed width */
    margin: 20px auto; /* Center card with margin */
}

.court-image {
    width: 100%; /* Image takes full width of the card */
    height: auto; /* Maintain aspect ratio */
}

.court-details {
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack vertically */
    align-items: center; /* Center content */
}

h2 {
    margin: 10px 0; /* Adjust top and bottom margin */
    font-size: 1.2em; /* Adjust font size */
}

.court-slot {
    width: 100%; /* Full width of the details section */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; /* Padding for each slot */
}

.court-time, .court-price {
    margin-right: 10px;
}

input[type="radio"] {
    margin-left: 10px;
    cursor: pointer;
}

.court-book-button {
    background-color: #0047AB;
    color: white;
    padding: 5px 10px; /* Smaller padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em; /* Smaller font size */
    width: 80px; /* Smaller width */
    margin: 10px 0; /* Margin on top and bottom */
}

.court-book-button:hover {
    background-color: #003484; /* Darker shade for hover state */
}

/* Responsive styles */
@media (max-width: 768px) {
    .court-result-item {
        width: 90%; /* Full width on small screens */
        margin: 20px 5%; /* Keep some margin on the sides */
    }
}

.distance-text {
    color: #808080; /* Faint gray color */
    font-size: 0.9rem; /* Smaller font size */
    margin-top: 5px; /* Spacing above the text */
  }

/* ClubProfile.css */
.club-profile-container {
    background-image: url('../assets/club_bckg.jpg'); /* Ensure the path is correct */
    display: flex;
    justify-content: center; /* Align children (form) to the right */
    align-items: center; /* Align children (form) vertically in the center */
    height: 100vh;
    background-size: cover;
    background-position: left center; /* Focus the background to the left */
    padding-left: 40%; /* Adjust this value as needed to position the form from the right */
}

.club-content-wrapper {
    width: 100%; /* Set the width to fit within the white space */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align the flex items horizontally */
}

.club-profile-form h1,
.club-profile-form label,
.club-profile-form input,
.club-profile-form .add-new-button,
.club-profile-form .submit-button {
    width: 100%; /* Make children take up full width of the form */
    text-align: center; /* Center text for all children */
    margin-bottom: 1em; /* Space out the children */
}

.submit-button {
    background-color: #a6cd3a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 0px; 
    margin-top: 20px 0;
}

.submit-button:hover {
    background-color: #a6cd3a;
    transform: scale(1.05);
}

.add-new-button {
  background-color: #a6cd3a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto; 
  margin-top: 20px 0;
}

.add-new-button:hover {
  background-color: #a6cd3a;
  transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
    .club-profile-container {
        justify-content: center; /* Center the form on smaller screens */
        padding-right: 0; /* Remove right padding on smaller screens */
    }

    .club-profile-form {
        width: 90%; /* Allow form to take more width on smaller screens */
    }
}

/* Tabs container */
.react-tabs {
    max-width: 600px; /* Set a max-width for the form and tabs */
    background: #0047abe6; /* Or any other background color */
    padding: 2em; /* Padding inside the card */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
    color: white;
    margin-bottom: 2em; /* Space out the form from the tabs */
  }
  
  /* Tab list */
  .react-tabs__tab-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  
  /* Tab */
  .react-tabs__tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
  }
  
  /* Selected tab */
  .react-tabs__tab--selected {
    background-color: #a6cd3a;
    color: white;
    border-radius: 5px;
  }
  
  /* Tab panels */
  .react-tabs__tab-panel {
    padding: 10px;
  }
  
  /* Style for hidden panels */
  .react-tabs__tab-panel--selected {
    display: block;
  }
  